@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-neutral-400 bg-[#e5e5e5];
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-neutral-500;
  }
  .h1 {
    @apply text-[40px] leading-[40px] lg:text-[60px] lg:leading-[62px] font-extrabold;
  }
  .h2 {
    @apply text-[32px] leading-[40px] lg:text-[50px] lg:leading-[50px] font-extrabold;
  }
  .h3 {
    @apply text-[38px] leading-[46px] font-extrabold;
  }
  .h4 {
    @apply text-[30px] leading-[46px] font-extrabold;
  }
  .h5 {
    @apply text-[24px] leading-[32px] font-extrabold;
  }
  .h6 {
    @apply text-[18px] leading-[28px] font-bold;
  }
  .text-body-sm {
    @apply font-secondary font-normal text-[16px] leading-[26px];
  }
  .text-body-md {
    @apply font-secondary font-normal text-[20px] leading-[32px];
  }
  .text-body-lg {
    @apply font-secondary font-normal text-[24px] leading-[34px];
  }
  .btn {
    @apply flex justify-center items-center rounded-[1px];
  }
  .btn-sm {
    @apply font-primary font-bold uppercase text-[12px] leading-[18px] h-[42px] px-[30px] tracking-[1px];
  }
  .btn-lg {
    @apply font-primary font-bold uppercase text-[14px] leading-[18px] h-[46px] px-[40px] tracking-[1px];
  }
  .btn-primary {
    @apply bg-primary-200 text-neutral-100 hover:bg-primary-200/90 transition;
  }
  .btn-secondary {
    @apply bg-white text-neutral-500 hover:bg-white/90 transition;
  }
  .link {
    @apply text-neutral-500 text-sm uppercase font-extrabold tracking-[1px];
  }
  .section {
    @apply pb-[80px] md:pb-[110px] lg:pb-[180px];
  }
  .section-title-group {
    @apply flex items-center justify-center -space-x-20 mb-[50px] lg:mb-[80px];
  }
  .section-title {
    @apply flex -space-x-4;
  }
}
.loader {
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-top: 4px solid white;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;
	margin: 0 auto;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

