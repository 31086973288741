.NavBar {
    width: 100%;
    height: 20px;
    background-color:  #524FF5;
  }
  /* styles.css */
header {
    transition: background-color 0.5s ease; 
  }

  /*Whatsapp button Styles*/
  .wa-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    z-index: 1000;
  }
  
  .wa-content {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .wa-content:hover {
    transform: translateY(-10px);
  }
  
  .wa-message {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px 15px;
    border-radius: 15px;
    margin-right: 10px;
    position: relative;
    animation: fadeOut 4s forwards;
    animation-delay: 6s;
  }
  
  .wa-message::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.7);
    border-left: 0;
    margin-right: -10px;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .wa-button {
    width: 60px;
    height: 60px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .wa-icon {
    font-size: 30px;
  }

.fade-out {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.6s ease;
}

  